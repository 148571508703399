import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'hp-mf/fdr/schemas/fragment/regular/fdr-link.generated';
import { FdrPriceFragmentDoc } from 'hp-mf/fdr/schemas/fragment/regular/fdr-price.generated';
import { FdrOfferRoomFragmentDoc } from 'hp-mf/fdr/schemas/fragment/room/fdr-room.generated';
import { FdrFlightFragmentDoc } from 'hp-mf/fdr/schemas/fragment/regular/fdr-flight.generated';
import { FdrImageFragmentDoc } from 'hp-mf/fdr/schemas/fragment/regular/fdr-image.generated';
import { FdrResizedFragmentDoc } from 'hp-mf/fdr/schemas/fragment/regular/fdr-resized.generated';
export type FdrWishlistProductOfferFragment = {
  __typename?: 'FdrProductOffer';
  id: string;
  departureDate: any;
  returnDate: any;
  duration: number;
  transferIncluded: boolean;
  travelType: Types.FdrProductTravelType;
  waitingScreenImage: string;
  provider?: string | null;
  flightAlternativesAvailable: boolean;
  webMeta: {
    __typename?: 'FdrSearchResultWebMeta';
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  };
  pricePerPerson: { __typename?: 'FdrPrice'; value: number; currency: string };
  priceTotal: { __typename?: 'FdrPrice'; value: number; currency: string };
  rooms: Array<{
    __typename?: 'FdrProductOfferRoom';
    adults: number;
    childrenDobs?: Array<any> | null;
    mealDescription: string;
    roomDescription: string;
    bookingCode: string;
    mealType: string;
    roomType: string;
  }>;
  transferPrice?: {
    __typename?: 'FdrPrice';
    value: number;
    currency: string;
  } | null;
  forwardFlight?: {
    __typename?: 'FdrFlight';
    duration: number;
    overnight: boolean;
    baggageInfo?: Array<string> | null;
    segments: Array<{
      __typename?: 'FdrFlightSegment';
      flightNumber: string;
      flightClass: Types.FdrFlightClass;
      duration: number;
      arrivalDate: any;
      arrivalTime: any;
      baggageIncluded: boolean;
      baggageInfo?: Array<string> | null;
      departureDate: any;
      departureTime: any;
      airportWaiting?: number | null;
      airline: { __typename?: 'FdrAirline'; iata: string; name: string };
      arrivalAirport: {
        __typename?: 'FdrAirport';
        name: string;
        isGroup: boolean;
        iata: string;
        codes?: Array<string> | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          longitude: number;
          latitude: number;
        } | null;
      };
      departureAirport: {
        __typename?: 'FdrAirport';
        name: string;
        isGroup: boolean;
        iata: string;
        codes?: Array<string> | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          longitude: number;
          latitude: number;
        } | null;
      };
    }>;
  } | null;
  returnFlight?: {
    __typename?: 'FdrFlight';
    duration: number;
    overnight: boolean;
    baggageInfo?: Array<string> | null;
    segments: Array<{
      __typename?: 'FdrFlightSegment';
      flightNumber: string;
      flightClass: Types.FdrFlightClass;
      duration: number;
      arrivalDate: any;
      arrivalTime: any;
      baggageIncluded: boolean;
      baggageInfo?: Array<string> | null;
      departureDate: any;
      departureTime: any;
      airportWaiting?: number | null;
      airline: { __typename?: 'FdrAirline'; iata: string; name: string };
      arrivalAirport: {
        __typename?: 'FdrAirport';
        name: string;
        isGroup: boolean;
        iata: string;
        codes?: Array<string> | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          longitude: number;
          latitude: number;
        } | null;
      };
      departureAirport: {
        __typename?: 'FdrAirport';
        name: string;
        isGroup: boolean;
        iata: string;
        codes?: Array<string> | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          longitude: number;
          latitude: number;
        } | null;
      };
    }>;
  } | null;
};

export type FdrWishlistProductOfferHeroFragment = {
  __typename?: 'FdrImage';
  alt: string;
  description?: string | null;
  copyright?: string | null;
  rawUrl: string;
  desaturated?: boolean | null;
  resized: Array<{
    __typename?: 'FdrResizedImage';
    id: string;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  }>;
};

export const FdrWishlistProductOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrWishlistProductOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departureDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceTotal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrPrice' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rooms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrOfferRoom' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrPrice' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transferIncluded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forwardFlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFlight' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFlight' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'waitingScreenImage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightAlternativesAvailable' },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
    ...FdrPriceFragmentDoc.definitions,
    ...FdrOfferRoomFragmentDoc.definitions,
    ...FdrFlightFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrWishlistProductOfferHeroFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrWishlistProductOfferHero' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrImage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'fdrImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resized' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '728' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '650' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'mode' },
                          value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '364' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '325' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'mode' },
                          value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '596' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '400' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'mode' },
                          value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '298' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '200' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'mode' },
                          value: { kind: 'EnumValue', value: 'FILL_DOWN' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrResized' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
