import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrWebMetaFragmentDoc } from 'hp-mf/fdr/schemas/fragment/web-meta/fdr-web-meta.generated';
import { FdrWishlistProductOfferHeroFragmentDoc } from './fdr-wishlist-product-offer.generated';
import { FdrFeatureGroupsFragmentDoc } from 'hp-mf/fdr/schemas/fragment/feature-groups/fdr-feature-groups.generated';
import { FdrCoordinatesFragmentDoc } from 'hp-mf/fdr/schemas/fragment/regular/fdr-coordinates.generated';
export type FdrWishlistItemProductFdrCruiseFragment = {
  __typename: 'FdrCruise';
  name: string;
  id: string;
  hero?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  summaryFeatureGroups?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      id: string;
      name: string;
      enabled: boolean;
    }>;
  }> | null;
  destination: {
    __typename?: 'FdrDestination';
    name: string;
    id: string;
    country: { __typename?: 'FdrCountry'; name: string; id: string };
  };
  hubData?: {
    __typename?: 'FdrHubProductData';
    taRating: number;
    taReviews: number;
  } | null;
  includedInPrice?: {
    __typename?: 'FdrTitleText';
    title: string;
    text: string;
  } | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    offersWebMeta?: {
      __typename?: 'FdrMeta';
      title: string;
      description: string;
      index: boolean;
      noFollow: boolean;
      sitemap: boolean;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrWishlistItemProductFdrHotelFragment = {
  __typename: 'FdrHotel';
  rating: number;
  mapZoom?: number | null;
  name: string;
  id: string;
  hero?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  summaryFeatureGroups?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      id: string;
      name: string;
      enabled: boolean;
    }>;
  }> | null;
  descriptionFeatureGroups?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      id: string;
      name: string;
      enabled: boolean;
    }>;
  }> | null;
  resort: {
    __typename?: 'FdrResort';
    name: string;
    id: string;
    destination: {
      __typename?: 'FdrDestination';
      name: string;
      id: string;
      country: { __typename?: 'FdrCountry'; name: string; id: string };
    };
  };
  tripAdvisorReviews?: {
    __typename?: 'FdrTripAdvisorReviewsResponse';
    totalRating?: number | null;
    numReviews?: number | null;
  } | null;
  coordinates?: {
    __typename?: 'FdrCoordinates';
    latitude: number;
    longitude: number;
  } | null;
  flags?: { __typename?: 'FdrHotelFlags'; top: boolean } | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    offersWebMeta?: {
      __typename?: 'FdrMeta';
      title: string;
      description: string;
      index: boolean;
      noFollow: boolean;
      sitemap: boolean;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrWishlistItemProductFdrRoundTripFragment = {
  __typename: 'FdrRoundTrip';
  name: string;
  id: string;
  hero?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  summaryFeatureGroups?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      id: string;
      name: string;
      enabled: boolean;
    }>;
  }> | null;
  destination: {
    __typename?: 'FdrDestination';
    name: string;
    id: string;
    country: { __typename?: 'FdrCountry'; name: string; id: string };
  };
  hubData?: {
    __typename?: 'FdrHubProductData';
    taRating: number;
    taReviews: number;
  } | null;
  includedInPrice?: {
    __typename?: 'FdrTitleText';
    title: string;
    text: string;
  } | null;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    offersWebMeta?: {
      __typename?: 'FdrMeta';
      title: string;
      description: string;
      index: boolean;
      noFollow: boolean;
      sitemap: boolean;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrWishlistItemProductFdrAdventureTravelFragment = {
  __typename: 'FdrAdventureTravel';
  name: string;
  id: string;
  webMeta: {
    __typename?: 'FdrProductWebMeta';
    offersWebMeta?: {
      __typename?: 'FdrMeta';
      title: string;
      description: string;
      index: boolean;
      noFollow: boolean;
      sitemap: boolean;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  };
};

export type FdrWishlistItemProductFragment =
  | FdrWishlistItemProductFdrCruiseFragment
  | FdrWishlistItemProductFdrHotelFragment
  | FdrWishlistItemProductFdrRoundTripFragment
  | FdrWishlistItemProductFdrAdventureTravelFragment;

export const FdrWishlistItemProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrWishlistItemProduct' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProduct' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offersWebMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrWebMeta' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrHotel' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrWishlistProductOfferHero',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summaryFeatureGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrFeatureGroups' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'descriptionFeatureGroups' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'activeOnly' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrFeatureGroups' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resort' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'destination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'id' },
                                    name: { kind: 'Name', value: 'publicId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tripAdvisorReviews' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'pagination' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'page' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'perPage' },
                                  value: { kind: 'IntValue', value: '1' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalRating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numReviews' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mapZoom' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrCoordinates' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrRoundTrip' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrWishlistProductOfferHero',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'summaryFeatureGroups' },
                  name: { kind: 'Name', value: 'featureGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrFeatureGroups' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hubData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taRating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taReviews' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'includedInPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrCruise' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrWishlistProductOfferHero',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'summaryFeatureGroups' },
                  name: { kind: 'Name', value: 'featureGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrFeatureGroups' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hubData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taRating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taReviews' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'includedInPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrWebMetaFragmentDoc.definitions,
    ...FdrWishlistProductOfferHeroFragmentDoc.definitions,
    ...FdrFeatureGroupsFragmentDoc.definitions,
    ...FdrCoordinatesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
